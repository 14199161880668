import React from "react";
import UnitedS from "./NewsPicture2";


export default function NewsBox2(){

    return(
        <div>
        <UnitedS/>
        <div>
            <h1>US President document case</h1>
            <p>Find out what the media is talking </p>
            <p>3 outlets are talking about this</p>
        </div>
    </div>
)
    
}