import React from "react";
import Testoidas from "./NewsPicture";

export default function NewsBox(){

    return(
        <div>
            <Testoidas/>
            <div>
                <h1>Ukraine - Russia conflict</h1>
                <p>Find out what the media is talking </p>
                <p>5 outlets are talking about this</p>
            </div>
        </div>
    )
}