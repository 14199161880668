import UKR from '../assets/img/ukr.jpg'
import '../App.css';


export default function Testoidas() {
        
 
        return(
            <img className='boximg' src={UKR} alt='ukraine'/>
        )
}