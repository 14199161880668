import UnitedS from '../assets/img/us.jpg'
import '../App.css';


export default function Testoidas2() {
        
 
        return(
            <img className='boximg' src={UnitedS} alt='united states'/>
        )
}